import { useSelector } from 'react-redux';
import './Header.styl';
import { getLiveGalleryStyleFromEventSettings, liveGalleryStyles } from '../../utils/liveGallery';

/**
 * @type {string|null} - Set a value to override the selected style for debugging purposes
 */
const DEBUG_LG_STYLE = null;

const Header = ({ eventId }) => {
  const event = useSelector((state) => state.activeEvent ?? {});

  // Settings
  /**
   * @type {string}
   */
  const liveGalleryStyle = DEBUG_LG_STYLE ?? getLiveGalleryStyleFromEventSettings(event);
  /**
   * @type {string}
   */
  const liveGalleryTheme = event.liveGallery?.theme ?? 'light';

  const isButtonEnabled = !!(event.buttonFlag && event.buttonText);
  const isOverlayVisible =
    !!event.background && ![liveGalleryStyles.minimal, liveGalleryStyles.square].includes(liveGalleryStyle);
  const isBackgroundVisible =
    !!event.background && ![liveGalleryStyles.minimal, liveGalleryStyles.square].includes(liveGalleryStyle);

  return (
    <section
      className={[
        'lghero',
        `lghero--${liveGalleryTheme}`,
        liveGalleryStyle && `lghero--${liveGalleryStyle}`,
        !!event.background && 'lghero--with-background',
        !!event.logo && 'lghero--with-logo',
      ]
        .filter(Boolean)
        .join(' ')}
      style={{ backgroundImage: isBackgroundVisible ? `url(${event.background})` : null }}
    >
      {isOverlayVisible && (
        <div className={['lghero__overlay', `lghero__overlay--${liveGalleryTheme}`].filter(Boolean).join(' ')} />
      )}
      {liveGalleryStyle === liveGalleryStyles.minimal && (
        <div className="lghero--minimal__featured-image">
          <img alt="" src={event.background} />
        </div>
      )}
      <div className="lghero__meta">
        {!!event.logo && <Logo link={event.logoLink} url={event.logo} />}
        {liveGalleryStyle === liveGalleryStyles.square && (
          <div className="lghero--square__featured-image">
            <img alt="" src={event.background} />
          </div>
        )}
        <header className="lghero__header">
          <h1 className="lghero__title">{event.title}</h1>
          {!!event.subtitle && <p className="lghero__subtitle">{event.subtitle}</p>}
        </header>
        {isButtonEnabled && (
          <a
            href={event.buttonLink}
            className="lghero__button"
            target="_blank"
            style={{
              backgroundColor: liveGalleryStyle !== liveGalleryStyles.modern && event.accentColor,
              color: liveGalleryStyle !== liveGalleryStyles.modern && event.contrastColor,
            }}
            rel="noreferrer"
          >
            {event.buttonText}
          </a>
        )}
      </div>
    </section>
  );
};

const Logo = ({ url, link }) => {
  const img = <img className="lghero__logo" src={url} alt="" />;

  if (link) {
    return <a href={link}>{img}</a>;
  }

  return img;
};

export default Header;
