import { useEffect, Children, cloneElement, useState } from 'react';
import InfiniteScrollComponent from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Spinner, Flex, Box } from '../atoms';
import { isElementScrollable } from '../../../utils/utils';

const InfiniteScroll = (props) => {
  const { children, loadMore, hasMore, dataLength, scrollableTarget, isMasonry } = props;

  // Keep track of whether scrollableTarget can be scrolled currently
  const [isScrollable, setIsScrollable] = useState();

  // Load the first page of a data set
  useEffect(() => {
    if (hasMore && !dataLength) {
      loadMore();
    }
  }, [hasMore, dataLength]);

  // Load next page(s), if possible, until the container overflows.
  // This ensures the visible area of the scrollableTarget fills
  // with items without requiring any actual scroll events.
  useEffect(() => {
    if (isScrollable || !dataLength) {
      return;
    }
    if (!isElementScrollable(scrollableTarget)) {
      loadMore();
    } else {
      // Set isScrollable so the isElementScrollable check
      // is skipped on subsequent renders
      setIsScrollable(true);
    }
  }, [dataLength, scrollableTarget, isScrollable]);

  const renderGridItems = () =>
    Children.map(children, (child) => (
      <Box
        as="li"
        sx={{
          p: !isMasonry && 4,
          textAlign: 'center',
          width: !isMasonry && ['100%', '50%', '33.33%', '25%'],
          listStyle: 'none',
        }}
      >
        {cloneElement(child)}
      </Box>
    ));

  return (
    <InfiniteScrollComponent
      next={loadMore}
      hasMore={hasMore}
      dataLength={dataLength}
      loader={
        <Flex sx={{ justifyContent: 'center', width: '100%', pt: 6 }}>
          <Spinner />
        </Flex>
      }
      element="ul"
      style={!isMasonry && { display: 'flex', flexWrap: 'wrap', overflowX: 'hidden' }}
      scrollableTarget={scrollableTarget}
    >
      {!isMasonry ? (
        renderGridItems()
      ) : (
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 767: 3, 1439: 4, 1919: 5 }}>
          <Masonry gutter="24px">{renderGridItems()}</Masonry>
        </ResponsiveMasonry>
      )}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
