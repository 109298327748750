const routes = require('next-routes-extended');

module.exports = routes()
  .add('splash', '/', 'index')
  .add('event-proposal', '/proposal/:eventId')
  .add('fiesta-event-proposal', '/proposals/:eventId')
  .add('virtual-booth', '/booth/:id')
  .add('welcome', '/admin/welcome')
  .add('events', '/admin/events')
  .add('webapp-event', '/admin/events/:eventId')
  .add('webapp-account', '/admin/account')
  .add('webapp-portfolio', '/admin/homepage')
  .add('webapp-marketing', '/admin/marketing')
  .add('webapp-academy', '/admin/academy/:vimeoId?')
  .add('login', '/login', 'login')
  .add('superuser-dashboard', '/successdashboard')
  .add('signup', '/signup', 'signup')
  .add('email-verification', '/email-verification/:token', 'email-verification')
  .add('resetpassword', '/resetpassword', 'resetpassword')
  .add('enternewpassword', '/resetpassword/:resetPasswordToken', 'enternewpassword')
  .add('checkout', '/checkout', 'checkout')
  .add('portfolio-iframe', '/portfolio-embed/:operatorId', 'portfolio-iframe')
  .add('fiesta-portfolio-iframe', '/fiesta-portfolio-embed/:operatorId', 'fiesta-portfolio-iframe')
  .add('portfolio', '/portfolio/:operatorId', 'index')
  .add('fiesta-portfolio-legacy', '/fiesta-portfolio/:operatorId', 'fiesta') // Keep this old route so older Fiesta will point to an existing page.
  .add('fiesta-portfolio', '/portfolios/:operatorId', 'fiesta')
  .add('photo-pending', '/qr/:sourceId/:eventId?', 'photo-pending')
  .add('analytics', '/:eventId/analytics/:analyticsToken', 'analytics')
  .add('photoPreview', '/:eventId/image', 'photo')
  .add('photo', '/:eventId/:photoId', 'photo')
  .add('event', '/:eventId', 'event');
