/**
 * Possible style options for the live gallery
 * @readonly
 * @enum {string}
 */
export const liveGalleryStyles = {
  default: 'default',
  modern: 'modern',
  square: 'square',
  minimal: 'minimal',
};

/**
 * @param {object} event - The event data
 * @returns {liveGalleryStyles}
 */
export const getLiveGalleryStyleFromEventSettings = (event) => {
  // Return default if no LG settings, or on Plus plan
  if (!event?.liveGallery || !event.liveGallery.liveGalleryStyle || event.organization?.plan === 'Plus') {
    return liveGalleryStyles.default;
  }
  return event.liveGallery.liveGalleryStyle;
};
